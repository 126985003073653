import EngineerInfoClass from './engineer/EngineerInfoClass';
import UserBaseInfo from './UserBaseInfo';

export default class UserInfo {
  userBaseInfo: UserBaseInfo;
  engineerinfo: EngineerInfoClass;
  toCSV: string;

  constructor(
    userBaseInfo?: UserBaseInfo,
    engineerinfo?: EngineerInfoClass,
    toCSV?: string

  ) {
    this.userBaseInfo = (userBaseInfo === undefined ? new UserBaseInfo() : userBaseInfo);
    this.engineerinfo = (engineerinfo === undefined ? new EngineerInfoClass() : engineerinfo);
    this.toCSV = (toCSV === undefined ? '' : toCSV);
  }

  public static cloneList(source: UserInfo[]): UserInfo[] {
    const result: UserInfo[] = [];
    source.forEach((s) => {
      result.push(UserInfo.clone(s));
    });
    return result;
  }

  public static clone(source: UserInfo): UserInfo {
    const result = new UserInfo();
    result.userBaseInfo = UserBaseInfo.clone(source.userBaseInfo);
    result.engineerinfo = EngineerInfoClass.clone(source.engineerinfo);
    result.toCSV = source.toCSV;
    return result;
  }

  public checkDate(): boolean {
    if (!this.userBaseInfo.jpBirthday.isValid) {
      return false;
    }
    if (!this.engineerinfo.dateEdu.isEmpty && !this.engineerinfo.dateEdu.isValid) {
      return false;
    }
    return true;
  }
}
