import TypeJpDate from '@/model/type/TypeJpDate';
import AddressInfo from '@/model/address/AddressInfo';
import Bank from './Bank';
import BankBranch from './BankBranch';
import BankNumber from './BankNumber';
import BankSubject from './BankSubject';
import Edu from './Edu';
import EmergencyName from './EmergencyName';
import EmergencyNumber from './EmergencyNumber';
import FamilySupport from './FamilySupport';
import Hoken from './Hoken';
import JobHistory from './JobHistory';
import Nenkin from './Nenkin';
import Onegai from './Onegai';
import FileId from '../../type/FileId';
import Syumi from './Syumi';
import TecrisCompany from './TecrisCompany';
import TecrisNumber from './TecrisNumber';
import Youbou from './Youbou';
import PcskillInfoList from '../pcskill/PcskillInfoList';
import FamilyInfo from '../family/FamilyInfo';
import PcSkillComment from './PcSkillComment';
import HistoryInfoClass from '../history/HistoryInfoClass';
import LicenseInfoClass from '../License/LicenseInfoClass';
import AddressLocation from '../AddressLocation';

export default class EngineerInfoClass {
  photo: FileId;

  tecrisnumber: TecrisNumber;

  tecriscompany: TecrisCompany;

  addresscorp: AddressInfo;

  edu: Edu;

  dateEdu: TypeJpDate;

  addresshotel: AddressInfo;

  addresshotelLocation: AddressLocation;

  emergencyName: EmergencyName;

  emergencyNumber: EmergencyNumber;

  familysupport: FamilySupport;

  nenkin: Nenkin;

  hoken: Hoken;

  nenkinfamily: Nenkin;

  pcskillComment: PcSkillComment;

  bank1: Bank;

  bank1Branch: BankBranch;

  bank1Subject: BankSubject;

  bank1Number: BankNumber;

  bank2: Bank;

  bank2Branch:BankBranch;

  bank2Subject:BankSubject;

  bank2Number: BankNumber;

  jobHistory: JobHistory;

  syumi: Syumi;

  youbou: Youbou;

  onegai: Onegai;

  pcskillInfoList: PcskillInfoList;

  familyinfoList: FamilyInfo[];

  historyinfoList: HistoryInfoClass[];

  licenseinfoList: LicenseInfoClass[];
  constructor(
    photo?: FileId,

    tecrisnumber?: TecrisNumber,

    tecriscompany?: TecrisCompany,

    addresscorp?: AddressInfo,

    edu?: Edu,

    dateEdu?: TypeJpDate,

    addresshotel?: AddressInfo,

    addresshotelLocation?: AddressLocation,

    emergencyName?: EmergencyName,

    emergencyNumber?: EmergencyNumber,

    familysupport?: FamilySupport,

    nenkin?: Nenkin,

    hoken?: Hoken,

    nenkinfamily?: Nenkin,

    pcskillComment?: PcSkillComment,

    bank1?: Bank,

    bank1Branch?: BankBranch,

    bank1Subject?: BankSubject,

    bank1Number?: BankNumber,

    bank2?: Bank,

    bank2Branch?: BankBranch,

    bank2Subject?: BankSubject,

    bank2Number?: BankNumber,

    jobHistory?: JobHistory,

    syumi?: Syumi,

    youbou?: Youbou,

    onegai?: Onegai,

    pcskillInfoList?: PcskillInfoList,

    familyinfoList?: FamilyInfo[],

    historyinfoList?: HistoryInfoClass[],

    licenseinfoList?: LicenseInfoClass[]

  ) {
    this.photo = (photo === undefined ? new FileId() : photo);
    this.tecrisnumber = (tecrisnumber === undefined ? new TecrisNumber() : tecrisnumber);
    this.tecriscompany = (tecriscompany === undefined ? new TecrisCompany() : tecriscompany);
    this.addresscorp = (addresscorp === undefined ? new AddressInfo() : addresscorp);
    this.edu = (edu === undefined ? new Edu() : edu);
    this.dateEdu = (dateEdu === undefined ? TypeJpDate.empty() : dateEdu);
    this.addresshotel = (addresshotel === undefined ? new AddressInfo() : addresshotel);
    this.addresshotelLocation = (addresshotelLocation === undefined ? new AddressLocation(0, 0) : addresshotelLocation);
    this.emergencyName = (emergencyName === undefined ? new EmergencyName() : emergencyName);
    this.emergencyNumber = (emergencyNumber === undefined ? new EmergencyNumber() : emergencyNumber);
    this.familysupport = (familysupport === undefined ? new FamilySupport() : familysupport);
    this.nenkin = (nenkin === undefined ? new Nenkin() : nenkin);
    this.hoken = (hoken === undefined ? new Hoken() : hoken);
    this.nenkinfamily = (nenkinfamily === undefined ? new Nenkin() : nenkinfamily);
    this.pcskillComment = (pcskillComment === undefined ? new PcSkillComment() : pcskillComment);
    this.bank1 = (bank1 === undefined ? new Bank() : bank1);
    this.bank1Branch = (bank1Branch === undefined ? new BankBranch() : bank1Branch);
    this.bank1Subject = (bank1Subject === undefined ? new BankSubject() : bank1Subject);
    this.bank1Number = (bank1Number === undefined ? new BankNumber() : bank1Number);
    this.bank2 = (bank2 === undefined ? new Bank() : bank2);
    this.bank2Branch = (bank2Branch === undefined ? new BankBranch() : bank2Branch);
    this.bank2Subject = (bank2Subject === undefined ? new BankSubject() : bank2Subject);
    this.bank2Number = (bank2Number === undefined ? new BankNumber() : bank2Number);
    this.jobHistory = (jobHistory === undefined ? new JobHistory() : jobHistory);
    this.syumi = (syumi === undefined ? new Syumi() : syumi);
    this.youbou = (youbou === undefined ? new Youbou() : youbou);
    this.onegai = (onegai === undefined ? new Onegai() : onegai);
    this.pcskillInfoList = (pcskillInfoList === undefined ? new PcskillInfoList() : pcskillInfoList);
    this.familyinfoList = (familyinfoList === undefined ? FamilyInfo.create() : familyinfoList);
    this.historyinfoList = (historyinfoList === undefined ? [] : historyinfoList);
    this.licenseinfoList = (licenseinfoList === undefined ? [] : licenseinfoList);
  }

  public static cloneList(source:EngineerInfoClass[]):EngineerInfoClass[] {
    const result : EngineerInfoClass[] = [];
    source.forEach((s) => {
      result.push(EngineerInfoClass.clone(s));
    });
    return result;
  }
  public static clone(source: EngineerInfoClass): EngineerInfoClass {
    const result = new EngineerInfoClass();
    result.photo = FileId.clone(source.photo);
    result.tecrisnumber = source.tecrisnumber;
    result.tecriscompany = source.tecriscompany;
    result.addresscorp = source.addresscorp;
    result.edu = source.edu;
    result.dateEdu = TypeJpDate.clone(source.dateEdu);
    result.addresshotel = source.addresshotel;
    result.addresshotelLocation = source.addresshotelLocation;
    result.emergencyName = source.emergencyName;
    result.emergencyNumber = source.emergencyNumber;
    result.familysupport = source.familysupport;
    result.nenkin = source.nenkin;
    result.hoken = source.hoken;
    result.nenkinfamily = source.nenkinfamily;
    result.pcskillComment = source.pcskillComment;
    result.bank1 = source.bank1;
    result.bank1Branch = source.bank1Branch;
    result.bank1Subject = source.bank1Subject;
    result.bank1Number = source.bank1Number;
    result.bank2 = source.bank2;
    result.bank2Branch = source.bank2Branch;
    result.bank2Subject = source.bank2Subject;
    result.bank2Number = source.bank2Number;
    result.jobHistory = source.jobHistory;
    result.syumi = source.syumi;
    result.youbou = source.youbou;
    result.onegai = source.onegai;
    result.pcskillInfoList = source.pcskillInfoList;
    result.familyinfoList = source.familyinfoList;
    result.historyinfoList = source.historyinfoList;
    result.licenseinfoList = source.licenseinfoList;
    return result;
  }

  public toDisplayNenkin():string {
    return `${this.nenkin.value.substring(0, 4)}-${this.nenkin.value.substring(4)}`;
  }

  public toDisplayHoken():string {
    return `${this.hoken.value.substring(0, 4)}-${this.hoken.value.substring(4, 10)}-${this.hoken.value.substring(10)}`;
  }
}
