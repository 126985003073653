
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import { AxiosPromise } from 'axios';
import { NavigationGuardNext, Route } from 'vue-router';
import UserBaseInfoForm from '@/components/User/UserBaseInfoForm.vue';
import EngineerInfo from '@/components/User/EngineerInfo.vue';
import EngineerRegister from '@/views/User/EngineerRegister.vue';
import UserApi from '@/module/api/UserApi';
import FamilyApi from '@/module/api/FamilyApi';
import FileUploadApi from '@/module/api/FileUploadApi';
import MapApi from '@/module/api/MapApi';
import UserInfo from '@/model/user/UserInfo';
import FamilyInfo from '@/model/user/family/FamilyInfo';
import UserBaseInfoClass from '@/model/user/UserBaseInfo';
import EngineerInfoClass from '@/model/user/engineer/EngineerInfoClass';
import UserUpdateItemInfo from '@/model/user/UserUpdateItemInfo';
import UpdateFields from '@/model/user/UpdateFields';
import UserID from '@/model/user/UserID';
import FileId from '@/model/type/FileId';
import BackButton from '@/components/Parts/BackButton.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave'
]);
@Component({
  components: {
    UserBaseInfoForm,
    EngineerInfo,
    EngineerRegister,
    BackButton
  }
})
export default class UserProfileEdit extends Vue {
  @State('loginUser') loginUser!: UserBaseInfoClass;
  @Getter('isAdminGroup')isAdminGroup!: boolean;
  @Getter('pageCache') getPageCache!: (key:string)=> any;
  @Mutation('commitPageCache') commitPageCache!: any;
  userId!:string;
  user:UserInfo= new UserInfo();
  appendedfamily:FamilyInfo[] = [];
  deletefamily:FamilyInfo[]= [];
  userUpdateItemList:UserUpdateItemInfo[]=[];
  showEngineerInfo:boolean = false;
  loadCompleted:boolean = false;
  fileId!:FileId;
  data = new FormData();
  displayPath: string = this.$route.path;

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<UserProfileEdit>): void {
    next((vm: UserProfileEdit) => {
      vm.rememberPageView();
    });
  }

  rememberPageView(): void {
    const cache = this.getPageCache(this.displayPath);
    if (cache) {
      this.showEngineerInfo = cache.showEngineerInfo;
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<UserProfileEdit>): void {
    this.commitPageCache(
      {
        pageName: this.displayPath,
        value: {
          showEngineerInfo: this.showEngineerInfo
        }
      }
    );
    next();
  }

  mounted(): void {
    this.loadData();
  }

  loadData():boolean {
    if (this.$route.params.userId === undefined || !this.loginUser.priv.isAdminGroup) {
      UserApi.findByID().then((res) => {
        this.user = UserInfo.clone(res.data);
        this.userId = this.user.userBaseInfo.userId.value;
      }).then(() => {
        this.getLocalData();
      });
      return true;
    }
    this.userId = this.$route.params.userId;
    UserApi.privfindByID(this.userId).then((res) => {
      this.user = UserInfo.clone(res.data);
    }).then(() => {
      this.getLocalData();
    });
    return true;
  }

  getLocalData():void{
    const userBase = localStorage.getItem(`user${this.user.userBaseInfo.userId.value}`);
    const enginnerBase = localStorage.getItem(`engineer${this.user.userBaseInfo.userId.value}`);
    if (userBase !== null) {
      this.user.userBaseInfo = UserBaseInfoClass.clone(JSON.parse(userBase));
    }
    if (enginnerBase !== null) {
      this.user.engineerinfo = EngineerInfoClass.clone(JSON.parse(enginnerBase));
    }
    this.loadCompleted = true;
  }

  addUpdateItem(item:UpdateFields):void{
    const check = this.userUpdateItemList.some((s) => s.updateFields === item);
    if (!check) {
      const userUpdateItem:UserUpdateItemInfo = new UserUpdateItemInfo(new UserID(this.userId), item);
      this.userUpdateItemList.push(userUpdateItem);
      localStorage.setItem(`updateList${this.user.userBaseInfo.userId.value}`, JSON.stringify(this.userUpdateItemList));
    }
  }

  fileUpload(file:File):void{
    this.data.append('upfile', file);
  }

  FileUploadMain(data:FormData):AxiosPromise<FileId> {
    this.data.append('fileId', this.user.engineerinfo.photo.value);
    return FileUploadApi.upload(data);
  }

  UserSave(user:UserInfo):void{
    if (this.isAdminGroup) {
      UserApi.privUpdate(user).then(() => {
        localStorage.removeItem(`user${this.user.userBaseInfo.userId.value}`);
        localStorage.removeItem(`engineer${this.user.userBaseInfo.userId.value}`);
        this.$root.$emit('event-show-snackbar', '登録完了しました');
      });
      return;
    }
    UserApi.update(user).then(() => {
      localStorage.removeItem(`user${this.user.userBaseInfo.userId.value}`);
      localStorage.removeItem(`engineer${this.user.userBaseInfo.userId.value}`);
      this.$root.$emit('event-show-snackbar', '登録完了しました');
    });
  }
  async save():Promise<void> {
    if (!this.user.checkDate()) {
      this.$root.$emit('event-show-snackbar-infinity', '入力内容に誤りがあります。');
      return;
    }
    /** 家族構成更新 */
    FamilyApi.update(this.user.engineerinfo.familyinfoList);
    /** 家族構成削除 */
    if (this.deletefamily.length !== 0) {
      FamilyApi.delete(this.deletefamily);
    }
    /** 家族構成新規 */
    if (this.appendedfamily.length !== 0) {
    // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.appendedfamily.length; i++) {
        this.$set(this.appendedfamily[i].userId, 'value', this.user.userBaseInfo.userId.value);
      }
      FamilyApi.save(this.appendedfamily);
    }
    const localUpdateData = localStorage.getItem(`updateList${this.user.userBaseInfo.userId.value}`);
    if (localUpdateData !== null) {
      this.userUpdateItemList = JSON.parse(localUpdateData);
      if (this.userUpdateItemList.filter((u) => u.updateFields.value === 'address').length) {
        try {
          const res = await MapApi.getLocation(this.user.userBaseInfo.addressPerson.address.value);
          this.user.userBaseInfo.addressLocation = res;
        } catch (err) {
          // 住所の座標変換に失敗しました。
        }
      }
      if (this.userUpdateItemList.filter((u) => u.updateFields.value === 'addresshotel').length) {
        try {
          const res = await MapApi.getLocation(this.user.engineerinfo.addresshotel.address.value);
          this.user.engineerinfo.addresshotelLocation = res;
        } catch (err) {
          // 住所の座標変換に失敗しました。
        }
      }
      if (!this.isAdminGroup) {
        // 管理者でなければ
        UserApi.saveUpdateItem(this.userUpdateItemList).then(() => {
          localStorage.removeItem(localUpdateData);
        });
      }
    }
    /** 写真アップロード */
    if (this.data.has('upfile')) {
      const res = await this.FileUploadMain(this.data);
      this.user.engineerinfo.photo = res.data;
    }
    this.UserSave(this.user);
  }

  /**
   * 登録ボタン使用不可判定
   */
  get disableSave(): boolean {
    // 入力不備あれば使用不可
    if (this.isInvalid()) return true;
    return false;
  }

  isInvalid():boolean {
    if (this.user.userBaseInfo.username.value.length === 0) return true;

    if (this.user.userBaseInfo.usernamekana.value.length === 0) return true;

    if (this.user.userBaseInfo.emailcell.value.length === 0 &&
     this.user.userBaseInfo.emailpc.value.length === 0) return true;

    return false;
  }
}
