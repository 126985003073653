import { AxiosPromise } from 'axios';
import FamilyInfo from '@/model/user/family/FamilyInfo';
import axios from '../AxiosBase';

export default {

  update(familyInfoList:FamilyInfo[]):AxiosPromise<any> {
    return axios.post('family/update', familyInfoList);
  },
  save(familyInfoList:FamilyInfo[]):AxiosPromise<any> {
    return axios.post('family/save', familyInfoList);
  },
  delete(familyInfoList:FamilyInfo[]):AxiosPromise<any> {
    return axios.post('family/delete', familyInfoList);
  }
};
