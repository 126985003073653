
import { Vue, Component, Prop } from 'vue-property-decorator';
import EngineerInfo from '@/components/User/EngineerInfo.vue';
import EngineerInfoClass from '@/model/user/engineer/EngineerInfoClass';
import FamilyInfo from '@/model/user/family/FamilyInfo';
import UserID from '@/model/user/UserID';
import UpdateFields from '@/model/user/UpdateFields';
import UserBaseInfo from '@/model/user/UserBaseInfo';

@Component({
  components: {
    EngineerInfo
  }
})
export default class EngineerRegister extends Vue {
  @Prop() loginUser!:UserBaseInfo;
  @Prop() isDisabled!:boolean;
  @Prop() engineer!:EngineerInfoClass;
  @Prop() family!:FamilyInfo[];
  @Prop() appendedfamily!:FamilyInfo[];
  @Prop() deletefamily!:FamilyInfo[];
  @Prop() userId!:UserID;
  @Prop() loadCompleted!:boolean;
  click():void {
    this.$emit('save');
  }
  changeItem(item:string):void{
    localStorage.setItem(`engineer${this.userId.value}`, JSON.stringify(this.engineer));
    this.$emit('addUpdateItem', new UpdateFields(item));
  }
  fileUpload(file:File):void{
    this.$emit('fileUpload', file);
  }
}
