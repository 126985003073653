import PcskillInfo from './PcskillInfo';

export default class PcskillInfoList {
  value: PcskillInfo[];

  constructor(
    value?: PcskillInfo[],
  ) {
    this.value = (value === undefined ? PcskillInfo.create() : value);
  }

  public static cloneList(source:PcskillInfoList[]):PcskillInfoList[] {
    const result: PcskillInfoList[] = [];
    source.forEach((s) => {
      result.push(PcskillInfoList.clone(s));
    });
    return result;
  }

  public static clone(source:PcskillInfoList):PcskillInfoList {
    const result = new PcskillInfoList();
    result.value = source.value;
    return result;
  }
}
